<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Contact Us</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="contact-title text-center mb-50">
            <h2>Our Institute is Available From 9:00 AM to 10:00 PM (Sunday - Thursday) and Contact is Available 24/7</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-item">
                    <div class="icon-contact text-center"><i class="far fa-clock"></i></div>
                    <h3>Sun - Thu : 9:00 AM - 10:00 PM</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-item">
                    <div class="icon-contact text-center"><i class="fas fa-map-marker-alt"></i></div>
                    <h3>28/A Street, New York City, USA</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-item text-center">
                    <div class="icon-contact"><i class="flaticon-telephone"></i></div>
                    <h3><a href="tel:+1123456789">+1 123 456 789</a></h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-form pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="contact-img">
                    <img src="assets/img/contact.png" alt="Contact Images">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="form-area">
                    <div class="form-section">
                        <h2>What Do You Want to Know?</h2>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control " required placeholder="Your Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject">
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-message textarea-hight" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn1 btn-two">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>