<header class="header-area top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="header-left">
                    <ul>
                        <li>
                            <i class="fas fa-clock"></i>
                            SAT - THU (10:00 AM - 6:00PM)
                        </li>
                        <li>
                            <i class="fas fa-map-marker-alt"></i>
                            28/A Street, New York, USA
                        </li>
                        <li>
                            <a href="tel:+1123456789">
                                <i class="fas fa-phone-alt"></i>
                                +1 123 456 789
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="social-icon">
                    <ul>
                        <li>
                            <a href="#" target="_blank">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li>  
                            <a href="#" target="_blank">
                                <i class="fab fa-google-plus-g"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <!-- <li class="nav-item">
                            <a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Home <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu"> -->
                                <!-- <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One </a></li> -->

                                <!-- <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li> -->
                            <!-- </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Servicios <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <!-- <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Book Appointment</a></li> -->

                                <li class="nav-item"><a routerLink="/instructor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terapia</a></li>
                                <li class="nav-item"><a routerLink="/instructor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Desarrollo Personal</a></li>
                                <li class="nav-item"><a routerLink="/instructor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Acompañamiento</a></li>

                                <!-- <li class="nav-item"><a routerLink="/courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li> -->

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <!-- <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                                
                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Page</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li> -->
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Services <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services </a></li>

                                <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">News <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/news" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News</a></li>

                                <li class="nav-item"><a routerLink="/news-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Details</a></li>
                            </ul>
                        </li>
                        
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Shop <i class="fas fa-chevron-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/shop" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop</a></li>
                                
                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/products-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul>

                    <div class="other-side">
                        <div class="search-area">
                            <div class="other-option">
                                <div class="search-item">
                                    <a href="#" class="search-btn"><i class="flaticon-loupe"></i></a>

                                    <i class="close-btn fas fa-times"></i>

                                    <div class="search-overlay search-popup">
                                        <div class="search-box">
                                            <form class="search-form">
                                                <input class="search-input" name="search" placeholder="Search" type="text">
                                                <button class="search-button" type="submit"><i class="flaticon-loupe"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="search-text">
                            Search
                        </div>
                        
                        <div class="cart-area">
                            <a class="cart-icon" routerLink="/cart"><i class="flaticon-shopping-cart"></i> <p>Cart <span>(2)</span></p></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>