<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape1 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>About Us</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-detl pt-100 pb-70">
    <div class="container">
        <div class="common-dtel-img">
            <img src="assets/img/about/about-detl.jpg" alt="About Images">
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="common-dtel-text">
                    <h2>Nuva is Popular Swimming & Diving Service Since 2009</h2>
                    <p>Satisfaction in of this hologram and more to elite. Caches away was remember harmonics. For achieveents children. Confidence will the feel in they a concept psychological the essential and years on then in snapped behind quietly founded, the a she and sported seven we've succeeded have her, interfaces small expect, to that, with too time can but met perfectly that it both from would indeed, that even have lowest more brief. Into step never are been for he that acquired to he groundtem, the empire own had multitude was go him over for an with that is completely me. Communicated.</p>
                    <p>Satisfaction in of this hologram and more to elite. Caches away was remember harmonics. For achieveents children. Confidence will the feel in they a concept psychological the essential and years on then in snapped behind quietly founded, the a she and sported seven we've succeeded have her.</p>
                    <p>Satisfaction in of this hologram and more to elite. Caches away was remember harmonics. For achieveents children. Confidence will  the feel in they a concept psychological the essential and years on then in snapped behind quietly founded, the a she and sported seven we've succeeded have her.</p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="common-offer">
                    <div class="drive-img">
                        <img src="assets/img/drive.png" alt="Images">
                    </div>
                    
                    <div class="common-offer-text">
                        <h3>Learn Swimming or Diving</h3>
                        <a href="tel:+0821425364" class="call-btn">Call Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="product-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Products </span>
            <h2>Buy the Equipment You Need</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/1.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Diving Glass</h3></a>
                            <span>$35</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/2.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Diving Rite</h3></a>
                            <span>$30</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/3.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Fins Bungee</h3></a>
                            <span>$75</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="product-item">
                    <a routerLink="/products-details"><img src="assets/img/product/4.jpg" alt="Product Images"></a>

                    <div class="product-cotent">
                        <div class="product-text">
                            <a routerLink="/products-details"> <h3>Life Jacket</h3></a>
                            <span>$25</span>
                        </div>

                        <a routerLink="/cart" class="add-product"><i class="flaticon-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="trainers-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Trainers</span>
            <h2>Our Professional Trainers</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/1.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>John Doe</h3></a>
                        <span>Professional Diver</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/2.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>Evana Doe</h3></a>
                        <span>Professional Trainer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/3.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content active">
                        <a routerLink="/instructor"><h3>Smith Doe</h3></a>
                        <span>Professional Swimmer</span>
                        <div class="social-icon active">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/4.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>Marida Doe</h3></a>
                        <span>Professional Instructor</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>