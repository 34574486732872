<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Pricing</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Pricing</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-card">
                    <div class="price-head">
                        <h2><sup>$</sup>45</h2>
                        <span>Private</span>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li><i class="far fa-check-circle"></i> Swimming Training 1 Person</li>
                            <li><i class="far fa-check-circle"></i> Swimming Drive 1 Person</li>
                            <li><i class="far fa-check-circle"></i> Swim Underwater 1 Person</li>
                            <li><i class="far fa-check-circle"></i> Scuba Drive 1 Person</li>
                            <li><i class="far fa-check-circle"></i> Snorkeling Drive 1 Person</li>
                        </ul>
                        <a routerLink="/" class="price-btn">Book Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-card">
                    <div class="price-head">
                        <h2><sup>$</sup>59</h2>
                        <span>Semi-Private</span>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li> <i class="far fa-check-circle"></i> Swimming Training 2 Person</li>
                            <li><i class="far fa-check-circle"></i> Swimming Drive 2 Person</li>
                            <li><i class="far fa-check-circle"></i> Swim Underwater 2 Person</li>
                            <li><i class="far fa-check-circle"></i> Scuba Drive 2 Person</li>
                            <li><i class="far fa-check-circle"></i> Snorkeling Drive 2 Person
                            </li>
                        </ul>
                        <a routerLink="/" class="price-btn">Book Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="pricing-card">
                    <div class="price-head">
                        <h2><sup>$</sup>69</h2>
                        <span>Group</span>
                    </div>
                    <div class="price-list">
                        <ul>
                            <li> <i class="far fa-check-circle"></i> Swimming Training 7 Person</li>
                            <li><i class="far fa-check-circle"></i> Swimming Drive 7 Person</li>
                            <li><i class="far fa-check-circle"></i> Swim Underwater 7 Person</li>
                            <li><i class="far fa-check-circle"></i> Scuba Drive 7 Person</li>
                            <li><i class="far fa-check-circle"></i> Snorkeling Drive 7 Person</li>
                        </ul>
                        <a routerLink="/" class="price-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>