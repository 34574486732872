<footer class="footer-area">
    <div class="footer-top pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="footer-content">
                        <div class="footer-conten-title">
                            <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a> 
                            <p>Lorem ipsum dolor sit amet, mattetur adipiscing elit, sed do eiusmod.</p>
                        </div>
                        
                        <div class="newsleter-area">
                            <h3>Join Newsletter</h3>
                            <form class="newsletter-form">
                                <input type="email" class="form-control" placeholder="Email" name="EMAIL" required autocomplete="off">
                                <button class="subscribe-btn" type="submit">Subscribe</button>
                            </form>
                        </div>

                        <div class="footer-social">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-pinterest"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-6">
                    <div class="footer-list">
                        <h3>Our Service</h3>

                        <ul>
                            <li><a routerLink="/services">Swimming Service</a></li>
                            <li><a routerLink="/services">Diving Service</a></li>
                            <li><a routerLink="/courses">Swimming Course</a></li>
                            <li><a routerLink="/courses">Diving Course</a></li>
                            <li><a routerLink="/services">Swimming Imported</a></li>
                            <li><a routerLink="/shop">Equipments</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="footer-list ml-50">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/faq">FAQ</a></li>
                            <li><a routerLink="/services">Services</a></li>
                            <li><a routerLink="/">Career</a></li>
                            <li><a routerLink="/courses">Courses</a></li>
                            <li><a routerLink="/privacy-policy">Privacy & Policy</a></li>
                            <li><a routerLink="/terms-condition">Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="footer-list">
                        <h3>Contacts</h3>

                        <ul>
                            <li><a href="tel:+1123456789"><i class="fas fa-mobile-alt"></i> +1 123 456 789</a></li>
                            <li><a href="tel:+1123456359"><i class="fas fa-mobile-alt"></i> +1 123 456 359</a></li>
                            <li><a href="mailto:email@nuva.com"><i class="far fa-envelope"></i> email@nuva.com</a></li>
                            <li><a href="mailto:hello@nuva.com"><i class="far fa-envelope"></i> hello@nuva.com</a></li>
                            <li><i class="fas fa-map-marker-alt"></i> 28/A street, New York, USA</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="footer-bottom">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="bottom-list">
                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/services">Services</a></li>
                            <li><a routerLink="/gallery">Gallery</a></li>
                            <li><a routerLink="/news">News</a></li>
                            <li><a routerLink="/shop">Shop</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="bottom-text">
                        <p>©2021 <strong>Nuva</strong>. All Rights Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootsrap</a> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>