<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape3 bg-color4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Instructor</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Instructor</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="instructor-img">
                    <img src="assets/img/trainers/instra1.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-8">
                <div class="instructor-content">
                    <h3>David Henry</h3>
                    <span>Chief Instructor</span>
                    <ul class="instructor-social">
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-google-plus-g"></i></a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ultrices velit metus, et ullamcorper dui porttitor ut. Ut quis nibh in turpis fermentum sagittis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu enim vitae elit convallis posuere eget sit amet diam. Suspendisse justo nulla, egestas non risus non, vulputate vulputate turpis. </p>
                    <a routerLink="/appointment" class="default-btn1" >Book Appointment</a>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="trainers-area pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Trainers</span>
            <h2>Our Professional Trainers</h2>
            <p>The introduced now, the they expect, animals the desk, and catch temple. More seven my couldn't it the character using recommended.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/1.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>John Doe</h3></a>
                        <span>Professional Diver</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/2.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>Evana Doe</h3></a>
                        <span>Professional Trainer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/3.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content active">
                        <a routerLink="/instructor"><h3>Smith Doe</h3></a>
                        <span>Professional Swimmer</span>
                        <div class="social-icon active">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="trainer-card">
                    <a routerLink="/instructor"><img src="assets/img/trainers/4.jpg" alt="Trainers Images"></a>

                    <div class="trainer-content">
                        <a routerLink="/instructor"><h3>Marida Doe</h3></a>
                        <span>Professional Instructor</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fas fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>