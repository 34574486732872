<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner bg-shape2 bg-color1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="conatiner">
                <div class="inner-title text-center">
                    <h3>Gallery</h3>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="gallery-inner pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center mb-50">
            <span>Our Gallery</span>
            <h2>Amazing Photos at Our Gallery</h2>
        </div>
        
        <div class="gallery-view">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/1.jpg" alt="Gallery Images">
                        <a href="assets/img/gallery/1.jpg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Baby Girl Learn Swimming</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/2.jpg" alt="Gallery Images">
                        <a href="assets/img/gallery/2.jpg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>John at His Swim Event</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/3.jpg" alt="Gallery Images">
                        <a href="assets/img/gallery/3.jpg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Scuba Diving Instruction</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/4.jpg" alt="Gallery Images">
                        <a href="assets/img/gallery/4.jpg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Under Water Scuba Diving</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/7.jpg" alt="Gallery Images">
                        <a href="assets/img/gallery/7.jpg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Mike’s Frist Dive for Nuva</h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-gallery">
                        <img src="assets/img/gallery/6.jpg" alt="Gallery Images">
                        <a href="assets/img/gallery/6.jpg" class="single-icon"><i class="fas fa-plus"></i></a>
                        <div class="gallery-content">
                            <h3>Boy Learning Swimming</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>